@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@font-face {
  font-family: "Avenir";
  src: url("./fonts/avenir/AvenirNextLTPro-Bold.otf");
}
@font-face {
  font-family: "Futura-light";
  src: url("./fonts/futura/futuralightc.otf");
  font-style: normal;
  }
::selection {
  background: #25f5a5; /* WebKit/Blink Browsers */
  color: #151726;
}
::-moz-selection {
  background: #25f5a5; /* Gecko Browsers */
  color: #151726;
}

h2 {
  color:#fff;
  font-size: 4vw;
  font-family: "Avenir";
  line-height: 1;
  font-weight: bold;
}
@media (max-width:40.063em) {
  h2 {
    color:#fff;
    font-size: 2.3rem;
    font-family: "Avenir";
    line-height: 1;
    font-weight: bold;
  }
}

p{
    line-height: 1.5;
    color: inherit;
    font-weight: 300;
    font-size: inherit;
    margin: 25px 0;
}


p:first-child {
    margin-top: 0
}


p:last-child {
    margin-bottom: 0
}
hr {
  display: block;
  width: 48px;
  height: 7px;
  margin: 10px auto;
  border: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAHCAYAAABOfc6SAAABg0lEQ…OIqnCR8emBybQ60VKvU2g9VY8EpE6RyPNgew0cuu8reVPoHeZzrpRYytGtAAAAAElFTkSuQmCC) no-repeat;
}


.header{
  position: fixed;
  height: 65px;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 1.5vh;
  padding-left: 3vw;
  padding-right: 3vw;
  transition: all 100ms ease;
}
@media (max-width:40.063em) {
  .header{
    height: 60px;
    padding-top: 1.5vh;
  }
  .header img{
    width: 40px; 
  }  
}

.header img{
  cursor: pointer;
  margin-top: 5px;
  filter: brightness(0) invert(1); 
}

@media (max-width:40.063em) {
  .header img{
    margin-top: 0px;
  }
}
.header img:hover{
  cursor: pointer;
  filter: none; 
}
.header h2 span:nth-child(odd){
  background: linear-gradient(45deg, #ff7300 33%, #bc188f 66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header h2 span{
  display: inline-flex;
}
.align-image{
  font-size: 34px;
  cursor: pointer;
}
.request-call-wrap{
  margin-top: 2.5vh;
}
.request-call-wrap button{
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  background: rgba(0, 0, 0, 0.1);
  height: 4.8vh;
  width: 14vw;
  border-radius: 30px;
}
.request-call-wrap button:hover{
  color: #eeb11b !important;
  border: 1px solid #eeb11b;
}
.request-call{
  color: #fff !important;
}
.request-call:hover{
  color: #eeb11b !important;
}
.mob-menu-phone{
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(0, 0, 0, 0.1);
  padding: 3px;
  padding-top: 5px;
  padding-bottom: 6px;
  border-radius: 30px;
}
.mob-menu-phone:hover{
  border: 1px solid #eeb11b;
  
}
.header h2 span:nth-child(even){
  overflow: hidden;
  color: #fff;
  transition: ease-in-out 0.5s;
  letter-spacing: -1em;
}
.header h2:hover span:nth-child(even){
  letter-spacing: 0;
}

.header h2:hover img{
  filter: none;
}

.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.header li {
  float: right;
}

.header li a {
  display: block;
  color: white;
  text-align: center;
  padding: 0;
  margin: 0 16px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  transition: all .25s ease-in-out;
  -khtml-transition: all .25s ease-in-out;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  
}

.header li a.active {
  box-shadow: 0 2px #eeb11b;
  -khtml-box-shadow: 0 2px #eeb11b;
  -webkit-box-shadow: 0 2px #eeb11b;
  -moz-box-shadow: 0 2px #eeb11b;
  -ms-box-shadow: 0 2px #eeb11b;
  -o-box-shadow: 0 2px #eeb11b;
}
.header li a:hover {
  color: #eeb11b;
}

.header .menu-items a{
  margin-top: 3.5vh;
}
.mob-menu-items{
  float: left !important;
}
@-webkit-keyframes mymove {
  from {top: 0px; font-size: 4vw;}
  to {top: -44vh;   font-size: 4vw;}
}

@keyframes mymove {
  from {top: 0px; font-size: 4vw;}
  to {top: -44vh; font-size: 4vw;}
}

.topNavBg {
  background-color: #fff;
}
.topNavBg #Navigation{
  transform: translate(0, -20%);
  transition: ease-in-out 0.5s;
}

.topNavBg #Navigation a{
  color: #00091F !important;
  transition: ease-in-out 0.5s;
}
.topNavBg #Navigation .request-call-wrap button{
  color: #fff !important;
  background: #0957ff;
}
.topNavBg #Navigation .request-call-wrap button:hover{
  border-color: #0957ff;
  color: #fff !important;
}
.topNavBg #Navigation a:hover{
  color: #eeb11b !important;
  transition: ease-in-out 0.1s;
  text-decoration: none;
}
.topNavBg .hidden-colibri-text{
  display: inline-block;
  padding-top: 4px;
  font-size: 34px;
}

.preloaderContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: all 100ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
}


*{
    margin: 0px;
    padding: 0px;
    outline: none;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
  background-color: #0957ff;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 75%, transparent);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

*:hover, *:focus, *:active{
  outline: none !important;
}
.context {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 33vh;
}

.bg-anim {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(./img/twinkling.png);
  animation: movingBackground 40s linear infinite;
}

@keyframes movingBackground {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: 1000px 0;
  }
}



@media (max-width:40.063em) {

.context{

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

}

.container .context.visible h3 {
  animation: fadeIn 500ms forwards 500ms, slideUp 500ms forwards 500ms;}

.container .context.visible svg {
  animation: fadeIn 300ms forwards 300ms, scaleUp 300ms forwards 300ms;
}
.container .context svg {
  opacity: 0;
}
.container .context h3 {
  text-align: center;
    margin-top: -6.5vw;
    padding: 0;
    font-size: 3vw;
    text-transform: capitalize;
    color: #fff;
    font-weight: 700;
    opacity: 0;
}

@media (max-width: 800px) {
  .context{
    margin-top: 6vw;
  }
.context h3{
  text-align: center;
  margin-top: -5vh;
  padding: 0;
  font-size: 24px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
}
}
.heart {
    fill: red;
    position: relative;
    left: 20px;
    top: 0;
    width: 50px;
    animation: pulse 1s ease infinite; 
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }

  svg {
    display: block;
    font: 10.5em 'Montserrat';
    width: 55vw;
    margin: 0 auto;
}

@media (max-width:40.063em) {
  svg {
    display: block;
    font: 11em 'Montserrat';
    width: 90vw;
    margin: 0 auto;
}
}

.text-copy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 5px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 5.5s infinite linear;
}

.text-copy:nth-child(1){
	stroke: #295EAF;
	animation-delay: -1;
}

.text-copy:nth-child(2){
	stroke: #eeb11b;
	animation-delay: -2s;
}

.text-copy:nth-child(3){
	stroke: rgb(0, 151, 189);
	animation-delay: -3s;
}

.text-copy:nth-child(4){
	stroke: #ff7300;
	animation-delay: -4s;
}

.text-copy:nth-child(5){
	stroke: #bc188f;
	animation-delay: -5s;
}

@keyframes stroke-offset{
	100% {stroke-dashoffset: -35%;}
}
.area, body{
    background: #00091F;
}
@media (min-width:40.063em) {
  .area{
    width: 100%;
    height: 100vh;
}
}

.area-2{
  overflow: hidden;
  width: 100%;
  background-image: url(./img/main-bg.jpg) !important;
  background-position: center bottom;
}



.area-3{
  width: 100%;
  background-image: url(./img/services-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position: center;
}


@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: #2834DF;
    animation: animate 25s linear infinite;
    box-shadow: inset -9px 0 0 0 #eeb11b, inset 9px 0 0 0 #eeb11b;
  -khtml-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
  -webkit-box-shadow: inset -9px 0 0 0 #eeb11b, inset 9px 0 0 0 #eeb11b;
  -moz-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
  -ms-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
  -o-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
}


.circles li:nth-child(1){
  bottom: -180px;
  left: -150px;
  width: 350px;
  height: 350px;
  animation-delay: 0s;
  animation-duration: 50s;
}


.circles li:nth-child(2){
    top: 27%;
    left: 88%;
    width: 300px;
    height: 300px;
    animation: none;
    border-radius: 50%;
    animation-delay: 2s;
    animation-duration: 30s;
}

@media (max-width:40.063em) {

.circles li:nth-child(3){
    top: 50px;
    left: -240px;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    animation-delay: 2s;
    animation-duration: 30s;
}
}
@media (min-width:40.063em) {

  .circles li:nth-child(3){
      display: none;
  }
  }
.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

  0%{
    transform: rotate(0deg) translateX(50px) rotate(0deg);
  border-radius: 50%;
  }
100%{
  transform: rotate(360deg) translateX(50px) rotate(-360deg);
  border-radius: 50%;
}
}

span.isvg {
    display: block;
    position: relative;
    width: 65vw;
    height: 121px;
    margin: 0 auto 0 auto;
    padding: 0 0;
}

span.isvg svg {
    overflow: visible
}

span.isvg:after,
span.isvg:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 15px;
    height: 121px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAB5CAYAAADmrXuWAAAAbklEQVRYR+3UsQ2AMAwAQbwZo7NZEHQU6AX1pbab08ez1tq3n2+u5Zk5/uxb/qgGDFgKiCSJngPAgKWASJLIrxKJSFJAJEnkkohEJCkgkiRySUQikhQQSRK5JCIRSQqIJIlcEpGIJAXuSHLqZeAEV2HjA6+sv/AAAAAASUVORK5CYII=) no-repeat;
}

span.isvg:before {
    left: 0
}

span.isvg:after {
    right: 0;
    transform: scaleX(-1);
    -khtml-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1)
}

.scrollContainer {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 12vh;
    cursor: pointer;
  }
.chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
  }
  
  .chevron:first-child {
    animation: move 3s ease-out 1s infinite;
  }
  
  .chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
  }
  
  .chevron:before,
  .chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
  }
  
  .chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
  }
  
  .chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }
  
  @keyframes move {
    25% {
      opacity: 1;
  
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }

  
  @media (max-width: 1200px){
  .full-page {
      height: auto !important;
      padding: 50px 25px;
  }
  }

  #fp-nav ul li a span, 
.fp-slidesNav ul li a span,
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span{
    width: 12px;
    height: 12px;
    margin: -8px 0 0 -8px;
    background: transparent;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 1px white;
    overflow: hidden;
}
#fp-nav ul li a span::after, 
.fp-slidesNav ul li a span::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    border-radius: 50%;
    left: 0;
    bottom: 0;
    background-color: #eeb11b;
    box-shadow: 0 0 0px #eeb11b;
 }
#fp-nav ul li a.active span, 
.fp-slidesNav ul li a.active span, 
#fp-nav ul li:hover a.active span, 
.fp-slidesNav ul li:hover a.active span {
    width: 12px;
    height: 12px;
    margin: -8px 0 0 -8px;
    background: transparent;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 2px #eeb11b;
}
    
#fp-nav ul li a.active span::after, 
.fp-slidesNav ul li a.active span::after, 
#fp-nav ul li:hover a.active span::after, 
.fp-slidesNav ul li:hover a.active span::after {
    height: 100%;
  }
  

  #fp-nav ul li .fp-tooltip {
    top: -8px;
    color: #fff;
    font-size: 16px;
    font-family: arial, helvetica, sans-serif;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    display: block;
    opacity: 0;
    width: 0;
    cursor: pointer;
}
#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    width: auto;
    opacity: 1;
}
#fp-nav ul li .fp-tooltip.fp-right {
    right: 25px;
}
#fp-nav ul li .fp-tooltip.fp-left {
    left: 20px;
}

/* Our Mission Section */


#header #mobile-menu .full-page ul, .full-page #header #mobile-menu ul, .full-page .centerY {
  width: 100%;
  margin: auto;
}

.full-page .wrapper {
  max-width: 85vw;
}
.full-page .col, .full-page .row {
  margin: auto;
  padding: 0;
}

.wrapper {
  max-width: 1170px;
  margin: auto;
  padding: 0 25px;
}
#footer-bottom, .clearfix, .journey, .wrapper, [class*=grid-], form[id^=gform_] .ginput_complex.gf_name_has_2 {
  zoom: 1;
}
.row {
  margin-right: auto;
}
.row, .row .col.s1, .row .col.s10, .row .col.s11, .row .col.s12, .row .col.s2, .row .col.s3, .row .col.s4, .row .col.s5, .row .col.s6, .row .col.s7, .row .col.s8, .row .col.s9 {
  margin-left: auto;
}


@media only screen and (min-width: 601px){
.row .col.m6 {
    width: 50%;
}

}
@media only screen and (min-width: 601px){
.row .col.m1, .row .col.m10, .row .col.m11, .row .col.m12, .row .col.m2, .row .col.m3, .row .col.m4, .row .col.m5, .row .col.m6, .row .col.m7, .row .col.m8, .row .col.m9 {
    margin-left: auto;
    left: auto;
    right: auto;
}
}
.row .col.s12 {
    width: 100% !important;
}
.row, .row .col.s1, .row .col.s10, .row .col.s11, .row .col.s12, .row .col.s2, .row .col.s3, .row .col.s4, .row .col.s5, .row .col.s6, .row .col.s7, .row .col.s8, .row .col.s9 {
    margin-left: auto;
}
.row .col.s1, .row .col.s10, .row .col.s11, .row .col.s12, .row .col.s2, .row .col.s3, .row .col.s4, .row .col.s5, .row .col.s6, .row .col.s7, .row .col.s8, .row .col.s9 {
    left: auto;
    right: auto;
}



.full-page .col, .full-page .row {
    margin: auto;
    padding: 0;
}
.row .col, ol, ul {
    padding: 0;
}
.row .col {
    padding: 0;
}
.row .col, nav ul li, nav ul.left {
    float: left;
}
.collapsible-body, .row .col, html, span.badge {
    box-sizing: border-box;
}


.full-page h3 {
    font: 800 1.1vw canada-type-gibson,sans-serif;
}
.full-page h1, .full-page h2, .full-page h3 {
    color: inherit;
    text-transform: uppercase;
}




.btn {
  display: inline-block;
  width: auto;
  height: auto;
  color: #FFF;
  font: 600 18px/1 canada-type-gibson,sans-serif;
  padding: 12px 22px;
  border: 2px solid transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  transition: .3s ease-in-out;
  -khtml-transition: .3s ease-in-out;
  -webkit-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  -ms-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
  background: 0 0;
  border-color: transparent #eeb11b;
}
.btn {
  position: relative;
  margin: 0;
}

.btn i {
  margin: 0 0 0 10px
}

.btn i.material-icons {
  opacity: .5;
  filter: alpha(opacity=50)
}
.arrow1{
  margin-left: 15px !important;
  opacity: .8;
}
i {
  opacity: 1;
  color: #fff;
  transition: opacity 0.2s ease-in;
}


@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}
.btn:before {
  top: -2px;
}
.btn:after {
  bottom: -2px;
}
.btn:after, .btn:before {
  position: absolute;
  right: -2px;
  left: -2px;
  height: 2px;
  transition: inherit;
  -khtml-transition: inherit;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -ms-transition: inherit;
  -o-transition: inherit;
  box-shadow: inset -9px 0 0 0 #eeb11b, inset 9px 0 0 0 #eeb11b;
  -khtml-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
  -webkit-box-shadow: inset -9px 0 0 0 #eeb11b, inset 9px 0 0 0 #eeb11b;
  -moz-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
  -ms-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
  -o-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
}

.btn:after, .btn:before {
  position: absolute;
  right: -2px;
  left: -2px;
  height: 2px;
  transition: inherit;
  -khtml-transition: inherit;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -ms-transition: inherit;
  -o-transition: inherit;
  box-shadow: inset -9px 0 0 0 #eeb11b, inset 9px 0 0 0 #eeb11b;
  -khtml-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
  -webkit-box-shadow: inset -9px 0 0 0 #eeb11b, inset 9px 0 0 0 #eeb11b;
  -moz-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
  -ms-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
  -o-box-shadow: inset -9px 0 0 0 #eeb11b,inset 9px 0 0 0 #eeb11b;
}

.btn:after, .btn:before {
  content: '';
}
.btn:hover {
  color: #FFF;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  background: rgba(255,255,255,.05);
}

.btn:hover:after, .btn:hover:before {
  color: #fff;
  box-shadow: inset -200px 0 0 0 #eeb11b, inset 200px 0 0 0 #eeb11b;
  -khtml-box-shadow: inset -200px 0 0 0 #eeb11b,inset 200px 0 0 0 #eeb11b;
  -webkit-box-shadow: inset -200px 0 0 0 #eeb11b, inset 200px 0 0 0 #eeb11b;
  -moz-box-shadow: inset -200px 0 0 0 #eeb11b,inset 200px 0 0 0 #eeb11b;
  -ms-box-shadow: inset -200px 0 0 0 #eeb11b,inset 200px 0 0 0 #eeb11b;
  -o-box-shadow: inset -200px 0 0 0 #eeb11b,inset 200px 0 0 0 #eeb11b;
  transition: .5s ease-in-out;
  -khtml-transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -ms-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.btn:focus, .btn:active {
  background-color: #eeb11b;
  outline: none !important;
  box-shadow: none;
}




/* Our Services */


.our-services-title{
  text-align: center;
  padding-top: 7.5vw;
  text-transform: uppercase;
  font: 300 18px canada-type-gibson,sans-serif;
  font-size: 16px !important;
}

@media (max-width:40.063em) {
  .our-services-title{
    text-align: center;
    text-transform: uppercase;
    font: 300 18px canada-type-gibson,sans-serif;
    font-size: 18px !important;
  }
}

@media (max-height: 800px){
.full-page {
    height: 102vh;
}
}
.full-page {
    position: relative;
    color: #FFF;
    text-align: center;
    background: center no-repeat;
    background-size: cover;
    -khtml-background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
}
.full-page {
    height: 100vh;
}
.full-page, .full-width {
    width: 100%;
}



.slide-insight figure {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  padding: 25px;
  margin: 30px;
  margin-bottom: 35px;
  border: 1px solid rgba(255, 255, 255, .1);
  transition: .8s .3s ease-in-out;
  -khtml-transition: .8s .3s ease-in-out;
  -webkit-transition: .8s .3s ease-in-out;
  -moz-transition: .8s .3s ease-in-out;
  -ms-transition: .8s .3s ease-in-out;
  -o-transition: .8s .3s ease-in-out;
  background: url(./img/bg-insight-card.png) center no-repeat rgba(0, 0, 0, .1)
}

@media (min-width: 1025px){
  .slide-insight figure {
      width: 267px;
      height: 379px;
  }
  }
  
@media (max-width:1025px) {
  .slide-insight figure {
      zoom: 1;
      display: block;
      width: 100%;
      margin: auto;
      padding: 10px 20px
  }
  .slide-insight figure:after,
  .slide-insight figure:before {
      content: '\0020';
      display: table;
      height: 0;
      overflow: hidden
  }
  .slide-insight figure:after {
      clear: both
  }
  .slide-insight figure:not(:last-child) {
      margin: 0 auto 10px
  }
}

.slide-insight figure strong {
  display: block;
  color: #FFF;
  font: 600 140px canada-type-gibson,sans-serif;
  transition: inherit;
  -khtml-transition: inherit;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -ms-transition: inherit;
  -o-transition: inherit;
}
.slide-insight figure img {
  margin-bottom: 30px;
  filter: brightness(0) invert(1);
}
.slide-insight figure:hover img {
  filter: none;
}

.slide-insight figure:hover {
  box-shadow: inset 0 0 0 2px #eeb11b;
  -khtml-box-shadow: inset 0 0 0 2px #eeb11b;
  -webkit-box-shadow: inset 0 0 0 2px #eeb11b;
  -moz-box-shadow: inset 0 0 0 2px #eeb11b;
  -ms-box-shadow: inset 0 0 0 2px #eeb11b;
  -o-box-shadow: inset 0 0 0 2px #eeb11b;
  transition: .0s ease-in-out;
  -khtml-transition: .0s ease-in-out;
  -webkit-transition: .0s ease-in-out;
  -moz-transition: .0s ease-in-out;
  -ms-transition: .0s ease-in-out;
  -o-transition: .0s ease-in-out;
  background-color: rgba(0,0,0,.35);
  
}

.slide-insight figure:hover strong {
  color: #eeb11b;
  
}


.slide-insight figure a {
  color: #FFF;
  font: 600 14px canada-type-gibson,sans-serif;
  text-decoration: none;
  text-transform: uppercase;
}

.slide-insight figure em {
  display: block;
  font: 300 18px canada-type-gibson,sans-serif;
  line-height: 26px;
  margin: 0 auto 25px;
}

.blog-pagination a.active, .blog-pagination a:hover, .gated-form form .btn, .slide-insight figure a:hover, .slide-insight figure:hover a {
  color: #eeb11b;
}

@media (max-width: 1025px){
.slide-insight figure:not(:last-child) {
    margin: 0 auto 10px;
}}
@media (max-width: 1025px){
.slide-insight figure {
    zoom: 1;
    display: block;
    width: 100%;
    margin: auto;
    padding: 10px 20px;
}
}
@media (min-width:1025px) {
  .slide-insight:not(.active) figure {
      opacity: 0;
      filter: alpha(opacity=0);
      transition: .25s ease-in-out;
      -khtml-transition: .25s ease-in-out;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -ms-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out
  }
  .slide-insight:not(.active) figure:first-child {
      transform: translate(0, -200px);
      -khtml-transform: translate(0, -200px);
      -webkit-transform: translate(0, -200px);
      -moz-transform: translate(0, -200px);
      -ms-transform: translate(0, -200px);
      -o-transform: translate(0, -200px)
  }
  .slide-insight:not(.active) figure:nth-child(2) {
      transform: translate(0, -100px);
      -khtml-transform: translate(0, -100px);
      -webkit-transform: translate(0, -100px);
      -moz-transform: translate(0, -100px);
      -ms-transform: translate(0, -100px);
      -o-transform: translate(0, -100px)
  }
  .slide-insight:not(.active) figure:nth-child(3) {
      transform: translate(0, -50px);
      -khtml-transform: translate(0, -50px);
      -webkit-transform: translate(0, -50px);
      -moz-transform: translate(0, -50px);
      -ms-transform: translate(0, -50px);
      -o-transform: translate(0, -50px)
  }
  .slide-insight:not(.active) .btn {
      opacity: 0;
      filter: alpha(opacity=0)
  }
}

@media (max-width: 1024px) {
  .slide-insight h3 {
    margin-bottom: 50px;
}
  .slide-insight .btn {
    margin-top: 50px;
    margin-bottom: 90px;
}
}

figure {
    margin: 1em 40px;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}


@keyframes appear {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
  }
  
  @keyframes slideIn {
  0% {
      transform: translateX(-2%);
  }
  100% {
      transform: translateX(0);
  }
  }

  @-webkit-keyframes fadeOut{
    0%   {opacity: 1;}
    100% {opacity: 0;}
}


.duration {
  font-size: 0.9rem;
}

.value {
  font-size: 2rem;
  font-weight: 700;
  padding-left: 3px;
}
.currency {
  display: inline-block;
  margin-top: 0px;
  vertical-align: top;
  padding-left: 3px;
  font-size: 1rem;
  font-weight: 700;
}
.ontop {
  position: absolute;
  top: 17%;
  left: 5vw;
  width: 60%;
}

@media (max-width:40.063em) {
  .ontop {
    position: absolute;
    top: 8%;
    left: 5vw;
    width: 90%;
  }
  .contact-wrap{
    position: absolute;
    padding-top: 15% !important;
    width: 100%;
    background: rgba(0,9,31, 1);

  }
}
.contact-row {
  display: flex;
  width: auto;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
  padding-right: 20px;
  background: rgba(0,9,31, .9);
}
.contact-row-sm{
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  background: rgba(0,9,31, .9);

}
.contact-wrap .contact-row-sm{
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
}
.contact-col {
  flex: 50%;
}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
label:focus{
  border: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow:none !important;
  
}

input:focus[type="text"], input[type="text"]:active, input:focus[type="password"], input[type="password"]:active {
  border: none;
  border-bottom: #eeb11b 1px solid;
  border-radius: 0;
  background: transparent;
  color: #fff;
}
input[type="text"], input[type="password"] {
  padding: 0;
  height: 45px;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  background: transparent;
  cursor: text;
  color: #fff;
}
input[type="submit"]{
  border: none;
  border-radius: 0;
  background: #f7decf;
  color: #fff;
  font-family: 'Futura-demi';
  letter-spacing: 0.8px;
  font-size: 13px;
  color: #000;
  width: 100%;
  height: 45px;
}

.form-control::-webkit-input-placeholder { color: #d1d1d1; letter-spacing: 0.5px;}  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #d1d1d1; letter-spacing: 0.5px;}  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #d1d1d1; letter-spacing: 0.5px;}  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #d1d1d1; letter-spacing: 0.5px;}  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #d1d1d1; letter-spacing: 0.5px;}  /* Microsoft Edge */

.boxes {
  padding-left: 25px;
  padding-right: 25px;
}

.boxes p{
  color: #d1d1d1;
}
.cbx{
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 18px;
}
/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  border: #666666 1px solid;
  background: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 8px;
  height: 12px;
  border: solid #eeb11b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.slide-ready h3{
  color: #fff;
  text-transform: uppercase;
  font-family: 'Avenir', sans-serif;
  font-size: 26px;
  letter-spacing: 2px;
}


input[type="checkbox"] { display: none; }

input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 16px/20px 'Open Sans', Arial, sans-serif;
  color: #ddd;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="checkbox"] + label:last-child { margin-bottom: 0; }

input[type="checkbox"] + label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #eeb11b;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .6;
  -webkit-transition: all .12s, border-color .08s;
  transition: all .12s, border-color .08s;
}

input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.address h1{
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
}

@media (max-width: 800px) {
  .address h1{
    font: 900 18px canada-type-gibson,sans-serif;
    margin-bottom: 30px;
  }
  .contact-wrap .address h1{
    font: 700 18px canada-type-gibson,sans-serif;
    font-size: 26px;
  }
  .address h3{
    margin-top: 10px !important;
  }

  .address button{
    margin-top: 30px !important;
  }
}
.address h3{
  color: #fff;
  font-size: 20px;
  text-transform: none;
  font-family: 'Future-light', sans-serif;
  margin: 10px 0 0px 0;
  line-height: 30px;
}

.address p{
  color: #fff;
  font-size: 16px;
}

#footer-bottom .social-links a:not(:last-child) {
  margin-right: 16px;
}
#footer-bottom .social-links a {
  display: inline-block;
  width: 55px;
  height: 55px;
  line-height: 50px;
  text-align: center;
  border: 1px solid rgba(78,82,110,.4);
  transition: .3s ease-in-out;
  -khtml-transition: .3s ease-in-out;
  -webkit-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  -ms-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
}
#footer-bottom .social-links a:hover {
  display: inline-block;
  text-align: center;
  border: 1px solid #eeb11b;
  transition: .3s ease-in-out;
  -khtml-transition: .3s ease-in-out;
  -webkit-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  -ms-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
}
@media (min-width: 40.063em){
#footer a, #footer button, #footer h2, #footer input, #footer textarea {
  pointer-events: auto;
}
}
#footer-bottom * {
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
}



a{
  color: inherit;
}
a:hover{
  color: #eeb11b;
  transition: ease-in-out 0.5s;
  text-decoration: none;
}
#footer-bottom .col:first-child>div {
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 800px) {
  #footer-bottom .social-links {
    text-align: center;
    margin: 20px;
  }
  .col #footer-bottom .social-links {
    text-align: left;
    margin: 30px;
    margin-left: 0;
  }
  .col #copyright {
    text-align: left;
  }
}

@media (min-width: 1025px){
#footer-bottom .social-links {
  position: relative;
  left: 41%;
  top: 30px;
  padding-bottom: 30px;
  transform: translate(-50%,0);
  -khtml-transform: translate(-50%,0);
  -webkit-transform: translate(-50%,0);
  -moz-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  -o-transform: translate(-50%,0);
}
}
#footer-bottom .social-links {
  margin-left: 25px;
}
#footer-bottom * {
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  bottom: 0;
}
i[class^=icon-] {
  background-repeat: no-repeat;
}
.material-icons, .posts-pagination a, i[class^=icon-] {
  display: inline-block;
  vertical-align: middle;
}
.icon-facebook {
  width: 11px;
  height: 19px;
  background: url(./img/icons-social.png) no-repeat;
}
.icon-instagram {
  width: 19px;
  height: 19px;
  background: url(./img/icons-social.png) -209px 0 no-repeat;
}
.icon-telegram{
  width: 20px;
  height: 20px;
  background: url('https://api.iconify.design/fa-brands:telegram-plane.svg?color=%23ffffff') no-repeat center center / contain;
}
i {
  line-height: inherit;
}

i {
  font-style: italic;
}





.full-page.darken-bg:before {
  content: '';
  pointer-events: none;
  z-index: 0;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, .08)
}

#header #mobile-menu .full-page.darken-bg>ul,
.full-page.darken-bg>.centerY {
  z-index: 2!important;
  position: relative!important
}

@media (min-width:40.063em) {
  .slide-mission {
      background-attachment: fixed
  }
  .slide-mission .mission-deco {
      width: 307px!important
  }
  .slide-mission .mission-deco-sm {
    display: none;
}
  .slide-mission .mission-deco h3 {
      line-height: 280px;
      text-indent: 35px
  }
  .slide-mission:not(.active) .mission-deco {
      opacity: 0;
      filter: alpha(opacity=0);
      transform: scale(.5);
      -khtml-transform: scale(.5);
      -webkit-transform: scale(.5);
      -moz-transform: scale(.5);
      -ms-transform: scale(.5);
      -o-transform: scale(.5)
  }
  .slide-mission .mission-text {
      position: relative;
      top: -5px;
      width: calc(100% - 307px)!important;
      padding-left: 155px
  }
  .slide-mission:not(.active) .mission-text .btn,
  .slide-mission:not(.active) .mission-text div {
      opacity: 0;
      filter: alpha(opacity=0)
  }
  .slide-mission:not(.active) .mission-text div {
      transform: translate(200px, 0);
      -khtml-transform: translate(200px, 0);
      -webkit-transform: translate(200px, 0);
      -moz-transform: translate(200px, 0);
      -ms-transform: translate(200px, 0);
      -o-transform: translate(200px, 0)
  }
  .slide-mission:not(.active) .mission-text .btn {
      transform: translate(0, 200px);
      -khtml-transform: translate(0, 200px);
      -webkit-transform: translate(0, 200px);
      -moz-transform: translate(0, 200px);
      -ms-transform: translate(0, 200px);
      -o-transform: translate(0, 200px)
  }
}

@media (max-width:40.063em) {
  .slide-mission {
    min-height: 0;
    background-image: none !important;
    display: none;
}
  
  .slide-mission .mission-deco {
      min-height: 0;
      background-image: none;
      display: none;
  }
  .slide-mission .mission-deco-sm h3 {
    position: absolute;
    top: 100px;
    font: 700 18px canada-type-gibson,sans-serif;
    font-size: 20px !important;
    opacity: 1;
    text-align: left;
}

}

.slide-mission {
  overflow: hidden;
  background-image: url(./img/main-bg.jpg);
  background-position: center bottom;
}

@media (min-width:40.063em) {
  .slide-mission {
      background-attachment: fixed
  }
  .slide-mission .mission-deco {
      width: 307px!important
  }
}

.slide-mission .mission-deco {
  min-height: 280px;
  transition: 1s ease-in-out;
  -khtml-transition: 1s ease-in-out;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -ms-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  background: url(./img/bg-mission-text.png) center no-repeat;
  background-size: contain;
  -khtml-background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -ms-background-size: contain;
  -o-background-size: contain
}

@media (max-width:40.063em) {
  .slide-mission .mission-deco {
      min-height: 0;
      background-image: none
  }
  .slide-mission .mission-deco h3 {
      text-align: left;
      margin: 0 0 42.5px;
  }
  .slide-mission p {
    margin: 120px 0 50px 0;
  }
  .slide-mission .btn {
    margin: 0 0 60px 0;
  }
}

.slide-mission .mission-text {
  text-align: left
}


@media (min-width:40.063em) {
  .slide-mission .mission-deco h3 {
      line-height: 280px;
      text-indent: 35px
  }

  .slide-mission p {
    font: 300 40px/58px 'Futura-light', sans-serif;
    margin: 0 0 50px;
    margin-top: 150px;
  }
  .slide-mission:not(.active) .mission-deco {
      opacity: 0;
      filter: alpha(opacity=0);
      transform: scale(.5);
      -khtml-transform: scale(.5);
      -webkit-transform: scale(.5);
      -moz-transform: scale(.5);
      -ms-transform: scale(.5);
      -o-transform: scale(.5)
  }
  .slide-mission .mission-text {
      position: relative;
      top: -5px;
      width: calc(100% - 307px)!important;
      padding-left: 155px
  }
  .slide-mission:not(.active) .mission-text .btn,
  .slide-mission:not(.active) .mission-text div {
      opacity: 0;
      filter: alpha(opacity=0)
  }
  .slide-mission:not(.active) .mission-text div {
      transform: translate(200px, 0);
      -khtml-transform: translate(200px, 0);
      -webkit-transform: translate(200px, 0);
      -moz-transform: translate(200px, 0);
      -ms-transform: translate(200px, 0);
      -o-transform: translate(200px, 0)
  }
  .slide-mission:not(.active) .mission-text .btn {
      transform: translate(0, 200px);
      -khtml-transform: translate(0, 200px);
      -webkit-transform: translate(0, 200px);
      -moz-transform: translate(0, 200px);
      -ms-transform: translate(0, 200px);
      -o-transform: translate(0, 200px)
  }
}

@media (min-width:40.063em) and (max-width:769px) and (orientation:portrait) {
  .slide-mission .mission-text {
      padding-left: 0
  }
}



@media (max-width:40.063em) {
  .slide-mission p {
      font-size: 28px;
      line-height: 1.35;
      padding-right: 25px
  }
}

@media (max-width:769px) and (orientation:portrait) {
  .slide-mission p {
      font-size: 28px;
      line-height: 1.35;
      padding-right: 25px
  }
}

.slide-mission .btn,
.slide-mission div {
  transition: 1s ease-in-out;
  -khtml-transition: 1s ease-in-out;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -ms-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out
}


@media (min-width:40.063em) {
  .slide-mission {
      background-attachment: fixed
  }
  .slide-mission .mission-deco {
      width: 307px!important
  }
  .slide-mission .mission-deco h3 {
      line-height: 280px;
      text-indent: 35px
  }
  .slide-mission:not(.active) .mission-deco {
      opacity: 0;
      filter: alpha(opacity=0);
      transform: scale(.5);
      -khtml-transform: scale(.5);
      -webkit-transform: scale(.5);
      -moz-transform: scale(.5);
      -ms-transform: scale(.5);
      -o-transform: scale(.5)
  }
  .slide-mission .mission-text {
      position: relative;
      top: -5px;
      width: calc(100% - 307px)!important;
      padding-left: 155px
  }
  .slide-mission:not(.active) .mission-text .btn,
  .slide-mission:not(.active) .mission-text div {
      opacity: 0;
      filter: alpha(opacity=0)
  }
  .slide-mission:not(.active) .mission-text div {
      transform: translate(200px, 0);
      -khtml-transform: translate(200px, 0);
      -webkit-transform: translate(200px, 0);
      -moz-transform: translate(200px, 0);
      -ms-transform: translate(200px, 0);
      -o-transform: translate(200px, 0)
  }
  .slide-mission:not(.active) .mission-text .btn {
      transform: translate(0, 200px);
      -khtml-transform: translate(0, 200px);
      -webkit-transform: translate(0, 200px);
      -moz-transform: translate(0, 200px);
      -ms-transform: translate(0, 200px);
      -o-transform: translate(0, 200px)
  }
}

@media (max-width:40.063em) {
  .slide-mission .mission-deco {
      min-height: 0;
      background-image: none
  }
  .slide-mission .mission-deco h3 {
      text-align: left;
      margin: 0 0 42.5px
  }
}

.dot{
	margin: auto auto;
	width: 300px;
	height: 300px;
	position: relative;
}


.centraldot{
	width: 6px;
	height: 6px;
	background: rgba(238,177,27,1);
	border-radius: 30px;
	position: absolute;

	animation: animationDotCentral linear 3s;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
}


.wave{
	width: 260px;
	height: 260px;
	background: rgba(238,177,27, 0.4);;
	border-radius: 200px;
	position: absolute;
	left: -127px;
	top: -127px;
	opacity: 0;
	animation: animationWave cubic-bezier(0,.54,.53,1) 3s;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards;
  animation-delay:0.9s;
  animation-iteration-count: infinite;
}

.wave2{
	width: 260px;
	height: 260px;
	background: rgba(238,177,27,0.4);
	border-radius: 200px;
	position: absolute;
	left: -127px;
	top: -127px;
	opacity: 0;
	animation: animationWave cubic-bezier(0,.54,.53,1) 3s;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards;
  animation-delay:1.07s;
  animation-iteration-count: infinite;
}


@keyframes animationDotCentral{
	
	0% {
		transform:  scale(0) ;
		opacity: 0;
	}
 
	5% {
		transform:  scale(2) ;
	}
  
	10% {
		transform:  scale(0.90) ;
		opacity: 1;
	}


	11% {
		transform:  scale(1.50) ;
	}

	12% {
		transform:  scale(1.00) ;
	}
 
	28% {
		background: rgba(238,177,27,1);;
	}
  
	29% {
		background: rgba(255,255,255,1);
	}

	31% {
		background: rgba(238,177,27,1);;
	}

	33% {
		background: rgba(255,255,255,1);
	}

	35% {
		background: rgba(238,177,27,1);;
	}

	90%{
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes animationWave{
	0% {
	    opacity: 0;
	    transform:  scale(0.00);
	}

	1% {
		opacity: 1;
	}

	10% {
		background: rgba(238,177,27, 0.4);;
	}

	100% {
	 	transform:  scale(1) ;
	 	background: rgba(238,177,27, 0.0);;
	 }
}



/* About Page Style */
#hero {
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
#hero .inner {
  position: relative;
  display: flex;
  align-items: center;
  color: white;
  height: 100%;
}
.inner {
  margin: 0 auto;
  width: 1200px;
}
#hero .inner #hero-content {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 3;
}
#hero-content-bg{
  position: absolute;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  bottom: 15px;
  top: 0;
}
#hero-content-bg h1{
  font-size: 126px !important;
  color: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
@media (max-width: 700px){
  #hero-content-bg h1{
    font-size: 70px !important;
    color: rgba(255, 255, 255, 0.1);
    overflow: hidden;
  }

}
#hero .inner h1 {
  font-size: 60px;
  text-transform: capitalize;
  opacity: 0;
  font-weight: 700;
  font-size: 64px;
  animation: fadeIn 400ms forwards 400ms, slideUp 400ms forwards 400ms;
}
#services-hero .inner h1 {
  font-size: 60px;
  text-transform: capitalize;
  opacity: 0;
  font-weight: 700;
  font-size: 64px;
  animation: fadeIn 400ms forwards 400ms, slideUp 400ms forwards 400ms;
}
#hero .inner p {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 400ms forwards 600ms, slideUp 600ms forwards 600ms;
}
#hero .inner #hero-illustration {
  width: calc(100% - 600px);
  z-index: 2;
  opacity: 0;
  transform: scale(0.96, 0.96);
  animation: fadeIn 600ms forwards 600ms, scaleUp 600ms forwards 600ms;
}
#hero .inner #hero-illustration img {
  height: 70vh;
}
#main-bg {
  position: fixed;
  background: #00091F;
}
#main-bg-anim {
  position: fixed;
  top: -100px !important;
  bottom: -100px !important;
  left: -100px !important;
  right: -100px !important;
  z-index: 2;
}
#main-bg-anim  > div {
  position: fixed !important;
  top: -100px !important;
  bottom: -100px !important;
  left: -100px !important;
  right: -100px !important;
}

/* #bg-screen {
  background-image: url(./img/bg-screen.svg);
} */
/* #bg-1 {
  background-image: url(./img/bg1.svg);
  z-index: 1;
} */
/* #bg-2 {
  background-image: url(./img/bg2.svg);
} */
/* #bg-3 {
  background-image: url(./img/bg3.svg);
} */
#hero-bg-bottom {
  position: relative;
  margin-top: 350px;
}
#hero-bg-bottom::before {
  content: "";
  display: block;
  height: 280px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(./img/bg-hero.svg);
  background-position: bottom center;
  margin-bottom: -2px;
}

#features {
  position: relative;
  padding: 100px 0 0;
  background-color: white;
  font-size: 18px;
  line-height: 30px;
  z-index: 3;
}
#features #features-header {
  text-align: center;
  margin-bottom: 80px;
}
#features #features-header.visible h2 {
  animation: fadeIn 600ms forwards, slideUp 600ms forwards;
}
#features #features-header h2 {
  color: #010101;
  font-size: 40px;
  line-height: 42px;
  opacity: 0;
  margin-bottom: 0.5em;
  font-weight: 100;
  font-family: "ProximaNova-Regular", sans-serif;
}
#features #features-header.visible .feature-description {
  animation: fadeIn 600ms forwards 300ms, slideUp 600ms forwards 300ms;
}
#features #features-header .feature-description {
  opacity: 0;
  color: #73738d;
  font-size: 1.2em;
  line-height: 1.6em;
}


#features .feature {
  display: flex;
  padding-bottom: 80px;
  max-width: 1000px;
  margin: auto;
  align-items: center;
}
#features .feature.beta-releases {
  padding-bottom: 30px;
}
#features .feature.visible .feature-illustration {
  animation: fadeIn 600ms forwards 300ms, slideUp 600ms forwards 300ms;
}
#features .feature .feature-illustration {
  flex: 1;
  text-align: center;
  opacity: 0;
}
#features .feature .feature-illustration svg {
  width: 80%;
  max-width: 600px;
  margin: auto;
}

svg:not(:root) {
  overflow: hidden;
}
#features .repositories{
  padding-top: 10px;
}
#features .feature.repositories .feature-content, #features .feature.dev-portal .feature-content {
  margin: 0 50px 0 0;
}
#features .feature .feature-content {
  width: 490px;
}
#features .feature.visible h3 {
  animation: fadeIn 600ms forwards, slideUp 600ms forwards;
}
#features .feature h3 {
  font-size: 28px;
  opacity: 0;
}
#features .feature.visible .feature-description {
  animation: fadeIn 600ms forwards 300ms, slideUp 600ms forwards 300ms;
}
#features .feature .feature-description {
  opacity: 0;
  color: #73738d;
  font-size: 1.2em;
  margin: 1em 0;
}

#features .feature-list {
  color: #73738d;
}

#features .feature.visible .feature-list li:nth-child(1) {
  animation-delay: 400ms;
}
#features .feature.visible .feature-list li {
  animation: fadeIn 600ms forwards 600ms, slideUp 600ms forwards 600ms;
}
#features .feature-list li {
  position: relative;
  padding-left: 25px;
  opacity: 0;
}
ol, ul {
  list-style: none;
}
#features .feature-list li::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    color: greenyellow;
    font-size: 0.9em;
}
#features .feature.configuration, #features .feature.beta-releases {
  flex-direction: row-reverse;
}

#features .feature.configuration, #features .feature.beta-releases {
  flex-direction: row-reverse;
}


#users-feedback-bg-bottom, #after-content {
  position: relative;
  height: 440px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(./img/about-bg-bottom.svg);
  background-position: bottom center;
  margin-top: -1px;
}

#footer-cta {
  position: relative;
  color: white;
  text-align: center;
  padding: 0 0 100px;
  z-index: 2;
}
@media (max-width: 700px){
  #footer-cta {
    padding-top: 80px;
  }
}
#footer-cta.visible h2 {
  animation: fadeIn 600ms forwards, slideUp 600ms forwards;
}
#footer-cta h2 {
  font-size: 40px;
  line-height: 44px !important;
  opacity: 0;
  font-weight: 700;
  text-transform: capitalize;
  font-family: sans-serif;
}
#footer-cta.visible p {
  animation: fadeIn 600ms forwards 300ms, slideUp 600ms forwards 300ms;
}
#footer-cta p {
  max-width: 600px;
  font-size: 1.2em;
  line-height: 1.2em;
  margin: 1.5em auto 2em auto;
  opacity: 0;
}
#footer-cta.visible #footer-cta-btn {
  animation: fadeIn 600ms forwards 600ms, slideUp 600ms forwards 600ms;
}
#footer-cta #footer-cta-btn {
  cursor: pointer;
  background: linear-gradient(9deg, #ff4d13 40%, #ee1a43 61%);
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: 220px;
  border-radius: 100px;
  margin: auto;
  box-shadow: 0 10px 80px rgba(0, 0, 0, 0.3);
  opacity: 0;
}

footer {
  position: relative;
  padding-bottom: 30px;
  z-index: 2;
}
footer #footer-content {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
footer #footer-content #footer-logo {
  width: 150px;
  margin: 0;
  padding: 0;
}
footer #footer-content #footer-social {
  width: 150px;
}
footer #footer-content #footer-social i:hover{
  opacity: 0.7;
  transition: opacity 0.2s ease-in;
}

footer #footer-content a {
  color: white;
  margin: 15px;
  transition: all 300ms ease-in-out;
}
#copyright {
  color: white !important;
  opacity: 1;
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
}
#main-copyright {
  color: white !important;
  opacity: 1;
  margin-top: 20px;
  font-size: 14px;
}
.section{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

@media (max-width: 1100px){
.inner {
    width: calc(100% - 100px);
}
}
@media (max-width: 1300px){
.inner {
    width: 1000px;
}
}
@media (max-width: 1300px){
#features #features-header h2 {
    width: 660px;
    margin: 0 auto 0.5em auto;
}
}



@media (max-width: 1000px){
#hero .inner h1 {
    font-size: 44px;
}
#services-hero .inner h1 {
  font-size: 44px;
}
}

@media (max-width: 700px){
#hero .inner #hero-illustration {
    display: none;
}
}
@media (max-width: 1000px){
#hero .inner #hero-illustration {
    margin-left: -100px;
}
}

@media (max-width: 460px){
.inner {
    width: calc(100% - 50px) !important;
}
}
@media (max-width: 1100px){
.inner {
    width: calc(100% - 100px);
}
}

@media (max-width: 700px){
#features {
    font-size: 18px;
    line-height: 23px;
    /* text-align: center; */
    padding: 65px 0 0;
}
}
@media (max-width: 1000px){
#features {
    font-size: 14px;
    line-height: 30px;
}
}

@media (max-width: 1000px){
#users-feedback-bg-bottom, #after-content {
    height: 100px;
}
#hero-bg-bottom::before {
    background-size: 220% 100%;
    background-position: left bottom;
}
}

@media (max-width: 800px){
#features #features-header {
    text-align: left;
}
}

@media (max-width: 800px){
#features #features-header h2 {
    width: auto !important;
    margin: 0 auto 0.5em auto;
    font-size: 36px;
    font-weight: 500;
}
}
@media (max-width: 1300px){
#features #features-header h2 {
    width: 660px;
    margin: 0 auto 0.5em auto;
}
}

@media (max-width: 800px){
#features #features-header .feature-description {
    font-size: 1.5em;
}
}

@media (max-width: 700px){
#features .feature {
    flex-direction: column;
    padding-bottom: 60px;

}
}

@media (max-width: 700px){
#features .feature .feature-content, #features .feature .feature-illustration {
    width: 100%;
}
}

@media (max-width: 700px){
#features .feature.repositories .feature-content, #features .feature.dev-portal .feature-content {
    margin: 20px 0 0 0;
}
}

@media (max-width: 700px){
#features .feature .feature-content, #features .feature .feature-illustration {
    width: 100%;
}
}
@media (max-width: 1000px){
#features .feature .feature-content {
    width: 310px;
}
}

@media (max-width: 700px){
#features .feature h3 {
    font-size: 24px;
}
}
@media (max-width: 1000px){
#features .feature h3 {
    font-size: 24px;
}
}

@media (max-width: 700px){
#features .feature-list {
    text-align: left;
}
}

@media (max-width: 700px){
#features .feature.configuration, #features .feature.beta-releases {
    flex-direction: column;
}
}

@media (max-width: 700px){
#footer-cta h2 {
    font-size: 32px;
    line-height: 32px;
}
}

@media (max-width: 700px){
footer #footer-content {
    flex-wrap: wrap;
    justify-content: center;
}
}
@media (max-width: 700px){
footer #footer-content #footer-logo {
    width: 100%;
    margin-bottom: 20px;
    order: 1;
}
.party {
  text-align: center !important;
  margin-left: 41%;
}
}

@media (max-width: 700px){
#footer-bottom #copyright{
  margin-bottom: 15px;
}
#copyright {
    color: white !important;
    width: 100%;
    text-align: center;
    order: 2;
}
}
@media (max-width: 700px){
footer #footer-content #footer-social {
    text-align: center;
    margin: 20px;
    order: 1;
}
}

.party {
  position: relative;
  text-align: center;
  transform: translate(0%, -50%);
  width: 48px;
  height: 20px;
}
.party * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: inherit;
  outline: none;
  font-weight: inherit;
  font-size: 1em;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.party:before {
  content: "";
  border-right: 24px solid transparent;
  border-bottom: 24px solid #b4b4f3;
  border-left: 24px solid transparent;
  width: 48px;
  height: 48px;
  animation: party-body 0.5s linear infinite;
}
.party ul {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #7272e9;
  position: absolute;
  top: 0;
  left: 8px;
  animation-name: party-head;
}
.party ul li:nth-child(3) {
  border-left: 4px solid transparent;
  border-top: 16px solid #f5d875;
  border-right: 4px solid transparent;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.party ul li:nth-child(2) {
  background: #222;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 8px;
  left: 8px;
}
.party ul li:nth-child(1) {
  background: #222;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 8px;
  right: 4px;
}
.party ul li:nth-child(4) {
  border-top: 6px solid transparent;
  border-left: 6px solid #3df2c2;
  border-bottom: 6px solid transparent;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateY(-50%);
  animation-name: party-plume;
}
@keyframes party-body {
  0%, 100% {
    border-right-width: 24px;
    border-left-width: 24px;
    border-bottom-color: #b4b4f3;
  }
  25% {
    border-right-width: 44px;
    border-left-width: 4px;
    border-bottom-color: #70eefa;
  }
  50% {
    border-right-width: 24px;
    border-left-width: 24px;
    border-bottom-color: #a7f9e3;
  }
  75% {
    border-right-width: 12px;
    border-left-width: 36px;
    border-bottom-color: #ff6270;
  }
}
@keyframes party-head {
  0%, 100% {
    transform: translate(0%, 0%) rotate(0deg);
    background: #7272e9;
  }
  25% {
    transform: translate(-37.5%, 12.5%) rotate(22.5deg);
    background: #51cfdb;
  }
  50% {
    transform: translate(0%, 25%);
    background: #3ad4ac;
  }
  75% {
    transform: translate(25%, 12.5%) rotate(-11.25deg);
    background: #e04351;
  }
}
@keyframes party-plume {
  0%, 100% {
    border-left-color: #3df2c2;
  }
  25% {
    border-left-color: #7272e9;
  }
  50% {
    border-left-color: #ff479e;
  }
  75% {
    border-left-color: #ff8c62;
  }
}

@-webkit-keyframes fadeIn{
  0%   { 
    opacity: 0; 
  }
  75% {
    opacity: 0.99; 
  }
100% { 
  opacity: 1; 
}
}
@keyframes fadeIn{
  0%   { 
    opacity: 0; 
  }

75% {
  opacity: 0.99; 
}

100% { opacity: 1; }}


/* Our Services */

#services-hero{
  position: relative;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;margin: 0;
}

#services-hero .inner {
  position: relative;
  display: flex;
  align-items: center;
  color: white;
  height: 100%;
}
#services-hero .inner #hero-content {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 2;
}
#services {
  position: relative;
  background: #fff;
  z-index: 3;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 1200px){
.promo h2 {
    margin-bottom: 5px;
}
}
.promo h2 {
    color: #22262a;
    font-family: MuseoSansCyrl-700,Roboto-Regular,'Segoe UI',Helvetica,Arial sans-serif;
    text-align: center;
    margin: 0 auto 50px auto;
    font-size: 42px;
}

.promo h4 {
  font-family: Roboto-Regular,'Segoe UI',Helvetica,Arial sans-serif;
  color: #73738d;
  text-align: center;
  margin: 0px auto 0 auto;
  padding: 20px 0 50px 0;
  font-size: 22px;
}

@media (max-width: 992px){
.promo .col-sm-12 {
    margin: 20px 0;
}
}
.promo .col-md-6 {
    margin: 1% 0;
}

@media (max-width: 992px){
.promo div.item {
    min-height: 300px;
}
}
.promo div.item {
    display: block;
    min-height: 385px;
    border-radius: 3px;
    background: #0957ff;
    text-align: left;
    color: #fff;
    position: relative;
    padding: 10px 25px;
}
.app-develope, .internet-market, .site-develope, .telegram-bot {
  position: absolute;
  bottom: 25px;
  right: 25px;
  display: block;
  margin: auto;
  opacity: .3;
  width: 40px;
  height: 40px;
}

@media (max-width: 1200px){
.promo div.item h3 {
    margin: 10px auto;
}
}
.promo div.item h3 {
    margin: 25px auto;
    z-index: 269;
    font-size: 30px;
    transition: margin .3s;
    font-family: MuseoSansCyrl-900,Roboto-Regular,'Segoe UI',Helvetica,Arial sans-serif;
    display: block;
    left: 0;
    right: 0;
}

@media (max-width: 992px){
.promo div.item span {
    height: auto;
}
}
.promo div.item span {
    display: block;
    z-index: 269;
    font-size: 16px;
    font-family: Roboto-Light,'Segoe UI',Helvetica,Arial sans-serif;
    line-height: 22px;
    transition: all .3s 0s;
    letter-spacing: 1px;
    height: 95px;
}

.promo div.item .price-info {
  font-weight: 700;
  font-family: Roboto-light,'Segoe UI',Helvetica,Arial sans-serif;
  font-size: 20px;
  position: absolute;
  bottom: 90px;
  right: 0;
  left: 25px;
}
.promo div.item b:nth-child(3) {
  font-weight: 100;
}

@media (max-width: 400px){
.promo div.item .price-info {
    font-size: 15px;
    right: 15px;
    left: 15px;
    bottom: 75px;
}
}

b, strong {
  font-weight: 700;
}
.promo div.item a.btn.small {
  position: absolute;
  left: 20px;
  bottom: 25px;
  font-size: 18px;
  font-family: Roboto-Light,'Segoe UI',Helvetica,Arial sans-serif;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: 600;
}

.scroll-downs {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width :34px;
  height: 55px;
}
@media (max-width: 800px){
  .scroll-downs {
    display: none;
  }
}
  .mousey {
  width: 3px;
  padding: 8px 10px;
  height: 25px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}


::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #859ddf;
}
::-webkit-scrollbar-thumb:hover {
  background: #0957ff;
}

.card-wrapper{
  width: 85%;
  margin: 0 auto;
  max-width: 80rem;
}

.cols{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.card-col{
  width: calc(50% - 2rem);
  margin: 1rem;
  cursor: pointer;
  
}

.card-container{
  background-color: transparent;
  -webkit-perspective: 1800px;
  perspective: 1800px;
  /* -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; */
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden; 
}

.front,
.back{
  background-size: cover;
	background-position: center;
	-webkit-transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
	-o-transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	text-align: center;
	min-height: 420px;
	height: auto;
	border-radius: 10px;
	color: #fff;
	font-size: 1.5rem;
}

.back{
  background: #0957ff;
  background-image: url(./img/services-bg.jpg);
}

.front:after{
	position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    opacity: .6;
    background-color: #000;
    border-radius: 10px;
}

.card-container:hover .front,
.card-container:hover .back{
    -webkit-transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.card-inner{
    /* -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
            transform: translateY(-50%) translateZ(60px) scale(0.94); */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    width: 100%;
    padding: 2rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
            perspective: inherit;
    /* transform-style: preserve-3d; */
    z-index: 2;
}

.card-container .back{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    /* -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;*/
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; 
}

.card-container .front{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    /* -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d; */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

}

.card-container:hover .back{
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  /* -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;*/
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.card-container:hover .front{
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  /* -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;*/
          -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.front .card-inner p{
  font-size: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.front .card-inner p:after{
  content: '';
  width: 4rem;
  height: 2px;
  position: absolute;
  background: #C6D4DF;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -.95rem;
}

.front .card-inner span{
  color: rgba(255,255,255,0.9);
  font-family: 'Montserrat';
  font-weight: 300;
}

@media screen and (max-width: 64rem){
  .card-col{
    width: calc(33.333333% - 2rem);
  }
}

@media screen and (max-width: 48rem){
  .card-col{
    width: calc(50% - 2rem);
  }
}

@media screen and (max-width: 32rem){
  .card-col{
    width: 100%;
    margin: 0 0 2rem 0;
  }
}


.contact-wrap{
  position: relative !important;
  width: 100%;
}


.contact-wrap span {
  position: absolute;
  margin: 0!important;
  padding: 0!important;
  left: 0;
  width: 100%;
  height: 60%;
}

.call-back-loader{
  position: relative;
  width: 60px;
  height: 60px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 40%;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.loader {
  position: relative;
  width: 60px;
  height: 60px;
  margin-top: 180px;
  margin-left: 35%;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.loader-star {
  position: absolute;
  top: calc(50% - 12px);
}
/*LOADER-1*/

.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #eeb11b;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #eeb11b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

@-webkit-keyframes loader-1-outter {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes loader-1-outter {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-inner {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
  }
}

.request-is-sent h1{
  color: #fff;
  font-size: 24px;
}
.request-is-sent p{
  color: #fff;
}

.party button {
  border:none;
}